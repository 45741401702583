import { FC, MouseEventHandler } from 'react';

import { OAuthSignInButton } from '..';

export const GoogleSignInButton: FC = () => {
	const onClick: MouseEventHandler<HTMLButtonElement> = _e => {
		console.log('TODO');
	};

	return (
		<OAuthSignInButton
			onClick={onClick}
			label="Sign in with Google"
			icon="google"
		/>
	);
};
