import { Children, FC } from 'react';
import { Grid, GridProps } from '@material-ui/core';

export const VerticleList: FC<GridProps> = ({
	children,
	spacing = 3,
	...props
}) => (
	<Grid
		container
		direction="column"
		alignItems="stretch"
		justify="center"
		wrap="nowrap"
		spacing={spacing}
		{...props}
	>
		{Children.map(children, child => child && <Grid item>{child}</Grid>)}
	</Grid>
);
