import React from 'react';

type HomeProps = React.HTMLAttributes<HTMLDivElement> & {
	prop?: string;
};

export const Home: React.FC<HomeProps> = ({ prop, ...props }) => {
	return (
		<div {...props} className="home">
			<h1>Home</h1>
		</div>
	);
};
