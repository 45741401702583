import React from 'react';

import { OAuthSignInButton } from '..';

type FacebookSignInButtonProps = React.HTMLAttributes<HTMLDivElement> & {
	prop?: string;
};

export const FacebookSignInButton: React.FC<FacebookSignInButtonProps> = () => {
	const onClick: React.MouseEventHandler<HTMLButtonElement> = _e => {
		console.log('TODO');
	};

	return (
		<OAuthSignInButton
			onClick={onClick}
			label="Sign in with Facebook"
			icon="facebook"
		/>
	);
};
