import { useState, useEffect } from 'react';
import { Divider } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { VerticleList, ButtonLink } from '../../../components';
import { jwtState } from '../../atoms';
import { OAuthSigninButtons, RegistrationForm } from '../../components';
import { useRegisterMutation } from '../../graphql';

export const Register: React.FC = () => {
	const navigate = useNavigate();
	const [error, setError] = useState<string>();
	const [
		register,
		{ data, error: apolloError, loading }
	] = useRegisterMutation();
	const setJwt = useSetRecoilState(jwtState);

	useEffect(() => {
		if (apolloError) {
			let error: string = apolloError.message;

			for (const graphqlError of apolloError.graphQLErrors) {
				if (
					graphqlError.extensions &&
					graphqlError.extensions.code === 'BAD_USER_INPUT'
				) {
					if (
						graphqlError.extensions.email &&
						graphqlError.extensions.email[0]
					) {
						error += ' ';
						error += graphqlError.extensions.email[0];
					}
				}
			}

			setError(error);
		}
	}, [apolloError]);

	useEffect(() => {
		if (data && data.register) {
			setJwt(data.register);

			navigate('/user');
		}
	}, [data, navigate, setJwt]);

	return (
		<VerticleList spacing={2}>
			<RegistrationForm
				loading={loading}
				register={(credentials, profile) =>
					register({ variables: { credentials, profile } })
				}
			/>

			{error && (
				<Alert
					style={{
						marginLeft: 0,
						marginRight: 0
					}}
					variant="filled"
					severity="error"
				>
					{error}
				</Alert>
			)}

			<ButtonLink to="/auth">
				Already have an account? Login here
			</ButtonLink>

			<Divider component="div" />

			<OAuthSigninButtons />
		</VerticleList>
	);
};
