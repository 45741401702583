import { from, HttpLink, ApolloClient, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { GRAPHQL_HTTP_URL } from './config';
import { cordovaHttpFetch } from './utils';

const setAuthorizationLink = setContext((_request, context) => {
	const recoilPersist = window.localStorage.getItem('recoil-persist');

	if (!recoilPersist) return context;

	const parsed = JSON.parse(recoilPersist);
	let token = parsed?.jwt;

	if (
		token === null ||
		token === undefined ||
		typeof token !== 'string' ||
		token === ''
	)
		return context;

	if (token.charAt(0) === '"') {
		token = token.substr(1);
	}

	if (token.charAt(token.length - 1) === '"') {
		token = token.substr(0, token.length - 1);
	}

	return {
		headers: { authorization: token }
	};
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors)
		graphQLErrors.map(({ message, locations, path }) =>
			console.log(
				`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
			)
		);

	if (networkError) console.log(`[Network error]: ${networkError}`);
});

const link = from([
	setAuthorizationLink,
	errorLink,
	new HttpLink({
		fetch: cordovaHttpFetch,
		uri: GRAPHQL_HTTP_URL
	})
]);

export const client = new ApolloClient({
	uri: GRAPHQL_HTTP_URL,
	link,
	cache: new InMemoryCache(),
	defaultOptions: {
		mutate: {
			errorPolicy: 'all'
		}
	}
});
