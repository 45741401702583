import { Card, Skeleton } from '../../components/Card';
import { useUser } from '../../hooks';

export const Profile: React.FC = ({ ...props }) => {
	const user = useUser();

	return (
		<div {...props} className="Profile">
			{user ? <Card {...user} /> : <Skeleton />}
		</div>
	);
};
