import clsx from 'clsx';
import { useRecoilValue } from 'recoil';
import { useRef, useState } from 'react';
import {
	IconButton,
	InputAdornment,
	StandardTextFieldProps,
	makeStyles,
	createStyles
} from '@material-ui/core';
import { Edit, DoneOutline, ErrorOutline } from '@material-ui/icons';

import { Input } from '..';
import { InputProps } from '../Input/Input';

export type EditableTextProps = InputProps & {
	noButton?: boolean;
	editOnFocus?: boolean;
};

const useStyles = makeStyles(theme => {
	const borderBottomColor = `${
		theme.palette.type === 'light'
			? 'rgba(0, 0, 0, 0)'
			: 'rgba(255, 255, 255, 0)'
	} !important`;

	return createStyles({
		notEditable: {
			'&:after': {
				borderBottomColor
			},
			'&:before': {
				borderBottomColor
			},
			'&:hover:before': {
				borderBottomColor
			}
		},
		pencil: {
			transition: theme.transitions.create('color', {
				duration: theme.transitions.duration.shortest
			}),
			color: 'rgba(0, 0, 0, 0.24)',
			'&:hover': {
				color: 'rgba(0, 0, 0, 0.54)'
			}
		}
	});
});

export const EditableText: React.FC<EditableTextProps> = ({
	noButton = false,
	editOnFocus = false,
	label,
	textFieldProps,
	validationState,
	touchedState,
	...props
}) => {
	const [editable, setEditable] = useState(false);
	const classes = useStyles();
	const touched = useRecoilValue(touchedState);
	const validation = useRecoilValue(validationState);
	const focusRef = useRef<HTMLInputElement>();

	const error = touched && validation !== undefined;

	const saveHandler = () => {
		if (validation === undefined) {
			setEditable(false);
		}
	};

	const endAdornment = (
		<InputAdornment position="end">
			<IconButton
				className={classes.pencil}
				onClick={e => {
					e.preventDefault();

					if (editable) {
						saveHandler();
					} else {
						setEditable(true);
						if (focusRef.current) {
							console.log('focusRef.current', focusRef.current);
							focusRef.current.focus();
						}
					}
				}}
			>
				{error ? (
					<ErrorOutline color="error" />
				) : editable ? (
					touched && <DoneOutline color="secondary" />
				) : (
					<Edit />
				)}
			</IconButton>
		</InputAdornment>
	);

	return (
		<Input
			label={label}
			validationState={validationState}
			touchedState={touchedState}
			textFieldProps={
				{
					autoComplete: 'off',
					label: undefined,
					margin: 'none',
					onBlur: saveHandler,
					onFocus: () =>
						noButton
							? !editable && setEditable(true)
							: editOnFocus && !editable && setEditable(true),
					InputProps: {
						color: 'secondary',
						className: clsx({
							[classes.notEditable]: !editable
						}),
						readOnly: !editable,
						'aria-roledescription': label,
						endAdornment: noButton ? undefined : endAdornment,
						inputRef: focusRef
					},
					...textFieldProps
				} as StandardTextFieldProps
			}
			{...props}
		/>
	);
};
