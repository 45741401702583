import React from 'react';

import { OAuthSignInButton } from '..';

type AppleSignInButtonProps = React.HTMLAttributes<HTMLDivElement> & {
	prop?: string;
};

export const AppleSignInButton: React.FC<AppleSignInButtonProps> = () => {
	const onClick: React.MouseEventHandler<HTMLButtonElement> = _e => {
		console.log('TODO');
	};

	return (
		<OAuthSignInButton
			onClick={onClick}
			label="Sign in with Apple ID"
			icon="apple"
		/>
	);
};
