import { Box, Container } from '@material-ui/core';

import { Nav, TopBar } from '..';

export const Layout: React.FC = ({ children }) => (
	<>
		<TopBar />

		<Nav />

		<Container fixed disableGutters maxWidth="xs">
			<Box p={3}>{children}</Box>
		</Container>
	</>
);
