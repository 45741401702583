import { atom, selector } from 'recoil';

import { FieldState, ValidationState, Validator } from '../../types';

export const makeFieldState = <T>(
	key: string,
	initialState: T,
	validator: Validator<T>
): FieldState<T> => {
	const state = atom<T>({
		key,
		default: initialState
	});

	const touchedState = atom<boolean>({
		key: `${key}Touched`,
		default: false
	});

	const validationState = selector<ValidationState>({
		key: `${key}Validation`,
		get({ get }) {
			const value = get(state);

			return validator(value, get);
		}
	});

	return {
		state,
		touchedState,
		validationState
	};
};
