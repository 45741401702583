import zxcvbn from 'zxcvbn';

import { ValidationError } from '../../types';

export const validatePassword = (
	input: string,
	field = 'password'
): ValidationError[] => {
	const output: ValidationError[] = [];

	const passwordStrength = zxcvbn(input);

	if (passwordStrength.score < 3) {
		for (const message of [
			...passwordStrength.feedback.suggestions,
			...(passwordStrength.feedback.warning === ''
				? []
				: [passwordStrength.feedback.warning])
		]) {
			output.push({
				field,
				message
			});
		}
	}

	return output;
};
