import { Button, Grid, Icon } from '@material-ui/core';
// import { Button, Icon } from '@material-ui/core';
import React from 'react';

type OAuthSignInButtonProps = {
	onClick: React.MouseEventHandler<HTMLButtonElement>;
	label: string;
	icon: string;
};

// export const OAuthSignInButton: React.FC<OAuthSignInButtonProps> = ({
// 	onClick,
// 	label,
// 	icon
// }) => {
// 	return (
// 		<Button fullWidth variant="outlined" onClick={onClick}>
// 			<div
// 				style={{
// 					display: 'flex',
// 					// flexWrap: 'nowrap',
// 					justifyContent: 'center',
// 					alignItems: 'center',
// 					width: '100%'
// 				}}
// 			>
// 				<div
// 					style={{
// 						textAlign: 'left',
// 						width: 15,
// 						marginRight: 10
// 					}}
// 				>
// 					<Icon
// 						component="i"
// 						fontSize="inherit"
// 						// color="action"
// 						className={`fab fa-${icon}`}
// 						style={{ overflow: 'visible' }}
// 					/>
// 				</div>

// 				<div style={{ textAlign: 'left', width: 175 }}>{label}</div>
// 			</div>
// 		</Button>
// 	);
// };

export const OAuthSignInButton: React.FC<OAuthSignInButtonProps> = ({
	onClick,
	label,
	icon
}) => {
	return (
		<Button fullWidth variant="outlined" onClick={onClick}>
			<Grid
				container
				justify="center"
				alignItems="center"
				spacing={3}
				style={{ width: 235 }}
			>
				<Grid item xs={2} style={{ textAlign: 'right' }}>
					<Icon
						component="i"
						fontSize="inherit"
						color="action"
						className={`fab fa-${icon}`}
						style={{ overflow: 'visible' }}
					/>
				</Grid>

				<Grid item xs={10} style={{ textAlign: 'left' }}>
					{label}
				</Grid>
			</Grid>
		</Button>
	);
};
