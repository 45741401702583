import {
	Menu as MaterialMenu,
	MenuProps as MaterialMenuProps,
	MenuItem
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { useUser } from '../../hooks';
import { jwtState } from '../../../Auth/atoms';
import { userMenuOpenState } from '../../atoms';

export type MenuProps = MaterialMenuProps;

export const Menu: React.FC<MenuProps> = ({ ...props }) => {
	// console.log('Menu');
	const navigate = useNavigate();
	const setJwt = useSetRecoilState(jwtState);
	const setUserMenuOpen = useSetRecoilState(userMenuOpenState);
	const user = useUser();

	const handler = (effect: () => void) => (_: React.SyntheticEvent) => {
		effect();
		setUserMenuOpen(false);
	};

	console.log('Menu: user', user);

	return (
		<MaterialMenu keepMounted {...props}>
			<MenuItem onClick={handler(() => navigate('/user/profile'))}>
				Profile
			</MenuItem>

			{user?.verified && (
				<MenuItem onClick={handler(() => navigate('/user/account'))}>
					My account
				</MenuItem>
			)}

			<MenuItem
				onClick={handler(() => {
					setJwt('');

					navigate('/');
				})}
			>
				Logout
			</MenuItem>
		</MaterialMenu>
	);
};
