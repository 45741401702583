import { useRecoilValue, useRecoilState } from 'recoil';

import { ValidationState, FieldState } from '../../types';

export type FieldBaseProps<T> = FieldState<T> & {
	children: (
		onChange: (val: T) => void,
		value: T,
		invalid: boolean,
		validation: ValidationState
	) => ReturnType<React.FC>;
};

export function FieldBase<T>({
	state,
	touchedState,
	validationState,
	children
}: FieldBaseProps<T>): ReturnType<FieldBaseProps<T>['children']> {
	const [value, setValue] = useRecoilState(state);
	const [touched, setTouched] = useRecoilState(touchedState);
	const validation = useRecoilValue(validationState);

	const isValidationSet = validation !== undefined;
	const invalid = touched ? isValidationSet : false;

	return children(
		val => {
			setValue(val);

			if (!touched) {
				setTouched(true);
			}
		},
		value,
		invalid,
		validation
	);
}
