import React from 'react';
import { OAuthSignInButton } from '..';

type TwitterSignInButtonProps = React.HTMLAttributes<HTMLDivElement> & {
	prop?: string;
};

export const TwitterSignInButton: React.FC<TwitterSignInButtonProps> = () => {
	const onClick: React.MouseEventHandler<HTMLButtonElement> = _e => {
		console.log('TODO');
	};

	return (
		<OAuthSignInButton
			onClick={onClick}
			label="Sign in with Twitter"
			icon="twitter"
		/>
	);
};
