import { useParams } from 'react-router-dom';
import { Box, createStyles, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { useResetPasswordMutation } from '../../graphql';
import { ButtonLink, Error, VerticleList } from '../../components';
import { PasswordResetForm } from '../../Auth/components';

const useStyles = makeStyles(theme =>
	createStyles({
		button: {
			textTransform: 'initial',
			fontWeight: 'normal',
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(1)
		}
	})
);

export const PasswordReset: React.FC = () => {
	const { token } = useParams();
	const [
		resetPassword,
		{ data, loading, error }
	] = useResetPasswordMutation();
	const styles = useStyles();

	return (
		<VerticleList spacing={2}>
			<PasswordResetForm
				success={data?.resetPassword}
				loading={loading}
				resetPassword={newPassword =>
					resetPassword({
						variables: {
							token,
							newPassword
						}
					})
				}
			/>

			{error && <Error message={error.message} />}

			{data && !data.resetPassword && (
				<Error message="The link you clicked has expired. Please request another password reset." />
			)}

			{data && data.resetPassword && (
				<Alert severity="success">
					<Box px={1}>Password reset successfully.</Box>

					<ButtonLink className={styles.button} to="/auth">
						Click here to login again.
					</ButtonLink>
				</Alert>
			)}
		</VerticleList>
	);
};
