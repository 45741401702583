import { atom, selector } from 'recoil';
import { ValidationState } from '../types';
import { makeFieldState, validateName } from '../utils';

export const userMenuOpenState = atom<boolean>({
	key: 'userMenuOpen',
	default: false
});

const nameValidator = (value: string) => {
	if (value === '') return ['This field is required.'];
	if (validateName(value)) return;

	return ['Invalid name.'];
};

export const editFirstNameState = makeFieldState<string>(
	'editFirstName',
	'',
	nameValidator
);

export const editLastNameState = makeFieldState<string>(
	'editLastName',
	'',
	nameValidator
);

export const nameTouchedState = selector<boolean>({
	key: 'nameTouched',
	get({ get }) {
		const firstNameTouched = get(editFirstNameState.touchedState);
		const lastNameTouched = get(editLastNameState.touchedState);

		return firstNameTouched || lastNameTouched;
	}
});

export const nameValidationState = selector<ValidationState>({
	key: 'nameValidation',
	get({ get }) {
		const firstNameValidation = get(editFirstNameState.validationState);
		const lastNameValidation = get(editLastNameState.validationState);

		if (
			firstNameValidation === undefined &&
			lastNameValidation === undefined
		)
			return;

		return [...(firstNameValidation ?? []), ...(lastNameValidation ?? [])];
	}
});
