import { makeStyles, createStyles, CircularProgress } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

import { useResendVerificationEmailMutation } from '../../graphql';
import { VerticleList, Error, ButtonLink } from '../../../components';

const useStyles = makeStyles(theme =>
	createStyles({
		button: {
			textTransform: 'initial',
			fontWeight: 'normal'
		},
		text: {
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(1)
		}
	})
);

export type VerificationNotificationProps = {
	userId: string;
};

export const VerificationNotification: React.FC<VerificationNotificationProps> = ({
	userId
}) => {
	const styles = useStyles();

	const [
		resendVerifcationEmail,
		{ data, error, loading }
	] = useResendVerificationEmailMutation({ variables: { userId } });

	return (
		<VerticleList spacing={2}>
			<Alert variant="outlined" severity="warning">
				<AlertTitle className={styles.text}>
					Please verify your email address.
				</AlertTitle>

				{loading ? (
					<div className={styles.text}>
						<CircularProgress size={20} />
					</div>
				) : data ? (
					data.resendVerificationEmail ? (
						<div className={styles.text}>
							Verification email resent! Please check your email.
						</div>
					) : (
						<Error
							variant="outlined"
							message="Failed to resend verification email. Please contact support."
						/>
					)
				) : (
					<ButtonLink
						className={[styles.text, styles.button].join(' ')}
						onClick={() => resendVerifcationEmail()}
					>
						Didn't get the email? Click here to resend.
					</ButtonLink>
				)}
			</Alert>

			{error && <Error message={error.message} />}
		</VerticleList>
	);
};
