import ReactDOM from 'react-dom';
import { BrowserRouter, HashRouter, MemoryRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { RecoilRoot } from 'recoil';

import { reportWebVitals } from './reportWebVitals';
import { client } from './apollo';
import { theme } from './theme';
import { Router } from './components';

import './sass/App.scss';

let ReactRouter: React.FC;

switch (process.env.REACT_APP_ROUTER_TYPE) {
	case 'hash':
		ReactRouter = HashRouter;
		break;
	case 'memory':
		ReactRouter = MemoryRouter;
		break;
	default:
		ReactRouter = BrowserRouter;
}

const App: React.FC = () => (
	<RecoilRoot>
		<ThemeProvider theme={theme}>
			<CssBaseline />

			<ApolloProvider client={client}>
				<ReactRouter>
					<Router />
				</ReactRouter>
			</ApolloProvider>
		</ThemeProvider>
	</RecoilRoot>
);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
