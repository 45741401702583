import { ValidationError } from '../../../types';
import { validatePassword } from '../../../utils';
import { ChangePasswordFormState } from '../../types';

export const validateChangePasswordForm = (
	state: ChangePasswordFormState
): ValidationError[] => {
	const errors: ValidationError[] = [];

	const passwordErrors = validatePassword(state.newPassword, 'newPassword');

	for (const error of passwordErrors) {
		errors.push(error);
	}

	if (state.newPassword !== state.confirmNewPassword) {
		errors.push({
			field: 'confirmNewPassword',
			message: 'Passwords do not match.'
		});
	}

	return errors;
};
