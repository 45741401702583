import { Skeleton as MaterialSkeleton } from '@material-ui/lab';

import { useStyles } from '../Card';

export type SkeletonProps = React.HTMLAttributes<HTMLDivElement>;

export const Skeleton: React.FC<SkeletonProps> = ({ ...props }) => {
	const styles = useStyles();

	return (
		<div {...props} className={styles.root}>
			<MaterialSkeleton
				variant="circle"
				height={40}
				width={40}
				component="div"
				style={{ maxWidth: 40 }}
			/>

			<div className={styles.right}>
				<MaterialSkeleton />

				<MaterialSkeleton />
			</div>
		</div>
	);
};
