import { HTTP } from '@ionic-native/http';

type FetchParams = Parameters<typeof fetch>;
type SendRequestParams = Parameters<typeof HTTP['sendRequest']>;

export const cordovaHttpFetch = async (
	url: string,
	options: FetchParams[1] & { [x: string]: unknown } = { method: 'GET' }
): Promise<Response> => {
	const cordova = window.cordova;

	// if cordova is not available use web fetch
	if (!cordova) {
		return fetch(url, options);
	}

	HTTP.setDataSerializer('json');

	options.data = JSON.parse(options.body as string);
	options.method = options.method?.toLowerCase();

	const response = await HTTP.sendRequest(
		url,
		options as SendRequestParams[1]
	);

	const responseBody =
		typeof response.data === `object`
			? JSON.stringify(response.data)
			: response.data;
	const headers = new Headers();
	Object.entries(response.headers).forEach(function ([key, value]) {
		headers.append(key, value as string);
	});

	return new Response(responseBody, {
		status: response.status,
		// statusText: HttpStatus.getStatusText(response.status),
		headers
	});
};
