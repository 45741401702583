import { TextField, TextFieldProps } from '@material-ui/core';

import { FieldState } from '../../types';
import { FieldBase } from '..';
import { normalizeLabel } from '../../utils';

export type InputProps = FieldState<string> & {
	label: string;
	name?: string;
	textFieldProps?: TextFieldProps;
};

export const Input: React.FC<InputProps> = ({
	label,
	name = normalizeLabel(label),
	textFieldProps = {},
	...props
}) => (
	<FieldBase {...props}>
		{(onChange, value, invalid, validation) => (
			<TextField
				type="text"
				id={name}
				name={name}
				label={label}
				error={invalid}
				value={value}
				helperText={invalid ? validation : undefined}
				onChange={e => onChange(e.target.value)}
				{...textFieldProps}
			/>
		)}
	</FieldBase>
);
