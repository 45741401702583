import { useCallback, useEffect } from 'react';
import { useEffectOnce } from 'react-use';
import { useRecoilState } from 'recoil';

// import { namedOperations } from '../../User/graphql';
import { useRefreshSessionMutation } from '../../graphql';
import { jwtState } from '../../Auth/atoms';

export const useRefreshSession = (onCompleted?: () => void): (() => void) => {
	const [jwt, setJwt] = useRecoilState(jwtState);
	const [refreshSession, { data }] = useRefreshSessionMutation({
		onCompleted
		// refetchQueries: [namedOperations.Query.User]
	});

	const refresh = useCallback(() => {
		if (jwt) {
			refreshSession({ variables: { token: jwt } });
		}
	}, [jwt, refreshSession]);

	useEffectOnce(refresh);

	useEffect(() => {
		if (data) {
			setJwt(data.refreshSession);
		}
	}, [data, setJwt]);

	return refresh;
};
