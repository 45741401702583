import {
	Button,
	ButtonProps,
	makeStyles,
	createStyles
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

export type ButtonLinkProps = ButtonProps & {
	to?: string;
};

const useStyles = makeStyles(_ =>
	createStyles({
		root: {
			textTransform: 'initial',
			fontWeight: 'normal'
		}
	})
);

export const ButtonLink: React.FC<ButtonLinkProps> = ({
	to,
	children,
	variant = 'text',
	size = 'small',
	disableRipple = true,
	...props
}) => {
	const navigate = useNavigate();
	const styles = useStyles();

	return (
		<Button
			variant={variant}
			size={size}
			className={styles.root}
			disableRipple={disableRipple}
			onClick={() => to && navigate(to)}
			{...props}
		>
			{children}
		</Button>
	);
};
