import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { green, blue } from '@material-ui/core/colors';

let theme = createMuiTheme({
	palette: {
		primary: {
			main: blue[500]
		},
		secondary: {
			main: green[500]
		}
	}
});

theme = responsiveFontSizes(theme);

export { theme };
