import { Button, TextField } from '@material-ui/core';
import { useState, HTMLAttributes, FC, useEffect } from 'react';
import { Plugins } from '@capacitor/core';

import { VerticleList, ButtonLink } from '../../../components';

const { Keyboard } = Plugins;

type LoginFormProps = HTMLAttributes<HTMLFormElement> & {
	login(email: string, password: string): void;
};

export const LoginForm: FC<LoginFormProps> = ({ login, ...props }) => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	useEffect(() => {
		Keyboard.setAccessoryBarVisible({ isVisible: false });
	}, []);

	return (
		<form
			{...props}
			onSubmit={e => {
				e.preventDefault();

				login(email, password);
			}}
			className="login-form"
		>
			<VerticleList spacing={2}>
				<TextField
					type="email"
					name="email"
					placeholder="Email address"
					value={email}
					onChange={e => setEmail(e.target.value)}
					required
					autoFocus
					fullWidth
				/>

				<TextField
					type="password"
					name="password"
					className="form-control mb-3"
					placeholder="Password"
					value={password}
					onChange={e => setPassword(e.target.value)}
					required
					fullWidth
				/>

				<ButtonLink to="/auth/forgot">Forgot your password?</ButtonLink>

				<Button
					fullWidth
					color="primary"
					variant="contained"
					type="submit"
				>
					Sign in
				</Button>
			</VerticleList>
		</form>
	);
};
