import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { NetworkStatus } from '@apollo/client';

import { useUserQuery, UserQuery } from '../../graphql';
import { userIdState } from '../../../Auth/atoms';

export type UseUserOptions = Omit<
	Parameters<typeof useUserQuery>[0],
	'variables' | 'skip'
>;

export const useUser = (
	options?: UseUserOptions
): UserQuery['user'] | undefined => {
	const id = useRecoilValue(userIdState);

	const { data, refetch, networkStatus } = useUserQuery({
		...options,
		variables: id ? { id } : undefined,
		skip: !id
	});

	useEffect(() => {
		if (id && networkStatus === NetworkStatus.ready) {
			refetch({ id });
		}
	}, [id, networkStatus, refetch]);

	return data?.user;
};
