import { FC } from 'react';

import { VerticleList } from '../../../components';
import {
	GithubSignInButton,
	GoogleSignInButton,
	FacebookSignInButton,
	TwitterSignInButton,
	AppleSignInButton
} from '..';

export const OAuthSigninButtons: FC = () => (
	<VerticleList spacing={2}>
		<GoogleSignInButton />

		<GithubSignInButton />

		<FacebookSignInButton />

		<TwitterSignInButton />

		<AppleSignInButton />
	</VerticleList>
);
