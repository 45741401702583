import { decode } from 'jsonwebtoken';

export const getUserIdFromJWT = (
	input: string | null | undefined
): string | undefined => {
	if (!input) return;

	const jwt = decode(input);

	if (typeof jwt !== 'object' || jwt === null) return;

	if (jwt.user === null || jwt.user === undefined) return;

	if (typeof jwt.user !== 'string') return;

	if (jwt.user === '') return;

	return jwt.user;
};
