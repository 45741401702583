import { useState } from 'react';
import { Button, CircularProgress, TextField } from '@material-ui/core';

import { VerticleList } from '../../../components';
import { Credentials, ProfileInput } from '../../graphql';
import { validateRegistrationForm } from '../../utils';

export type RegistrationFormProps = React.HTMLAttributes<HTMLFormElement> & {
	register(credentials: Credentials, profile: ProfileInput): void;
	loading: boolean;
};

export const RegistrationForm: React.FC<RegistrationFormProps> = ({
	register,
	loading,
	...props
}) => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');

	const [emailErrors, setEmailErrors] = useState<string[]>([]);
	const [passwordErrors, setPasswordErrors] = useState<string[]>([]);
	const [firstNameErrors, setFirstNameErrors] = useState<string[]>([]);
	const [lastNameErrors, setLastNameErrors] = useState<string[]>([]);
	const [confirmPasswordErrors, setConfirmPasswordErrors] = useState<
		string[]
	>([]);

	return (
		<form
			{...props}
			onSubmit={e => {
				e.preventDefault();

				if (loading) {
					return;
				}

				const validationErrors = validateRegistrationForm({
					email,
					password,
					confirmPassword,
					firstName,
					lastName
				});

				setEmailErrors(
					validationErrors
						.filter(error => error.field === 'email')
						.map(({ message }) => message)
				);
				setPasswordErrors(
					validationErrors
						.filter(error => error.field === 'password')
						.map(({ message }) => message)
				);
				setFirstNameErrors(
					validationErrors
						.filter(error => error.field === 'firstName')
						.map(({ message }) => message)
				);
				setLastNameErrors(
					validationErrors
						.filter(error => error.field === 'lastName')
						.map(({ message }) => message)
				);
				setConfirmPasswordErrors(
					validationErrors
						.filter(error => error.field === 'confirmPassword')
						.map(({ message }) => message)
				);

				if (validationErrors.length === 0) {
					register({ email, password }, { firstName, lastName });
				}
			}}
			className="registration-form"
		>
			<VerticleList spacing={2}>
				<TextField
					type="email"
					name="email"
					placeholder="Email address"
					value={email}
					onChange={e => setEmail(e.target.value)}
					required
					autoFocus
					fullWidth
					error={emailErrors.length > 0}
					helperText={emailErrors.join(' ')}
				/>

				<TextField
					type="password"
					name="password"
					placeholder="Password"
					value={password}
					onChange={e => setPassword(e.target.value)}
					required
					fullWidth
					error={passwordErrors.length > 0}
					helperText={passwordErrors.join(' ')}
				/>

				<TextField
					type="password"
					name="confirm-password"
					placeholder="Confirm Password"
					value={confirmPassword}
					onChange={e => setConfirmPassword(e.target.value)}
					required
					fullWidth
					error={confirmPasswordErrors.length > 0}
					helperText={confirmPasswordErrors.join(' ')}
				/>

				<TextField
					type="text"
					name="first-name"
					placeholder="First Name"
					value={firstName}
					onChange={e => setFirstName(e.target.value)}
					required
					fullWidth
					error={firstNameErrors.length > 0}
					helperText={firstNameErrors.join(' ')}
				/>

				<TextField
					type="text"
					name="last-name"
					placeholder="Last Name"
					value={lastName}
					onChange={e => setLastName(e.target.value)}
					required
					fullWidth
					error={lastNameErrors.length > 0}
					helperText={lastNameErrors.join(' ')}
				/>

				<Button
					fullWidth
					color="primary"
					variant="contained"
					type="submit"
				>
					{loading ? (
						<CircularProgress color="inherit" size={20} />
					) : (
						'Sign Up'
					)}
				</Button>
			</VerticleList>
		</form>
	);
};
