import { useEffect, useState } from 'react';
import { TextField, Button, CircularProgress } from '@material-ui/core';

import { VerticleList } from '../../../components';

export type RequestPasswordResetFormProps = React.HTMLAttributes<HTMLFormElement> & {
	resetPassword(email: string): void;
	loading: boolean;
	success?: boolean;
};

export const RequestPasswordResetForm: React.FC<RequestPasswordResetFormProps> = ({
	resetPassword,
	loading,
	success,
	...props
}) => {
	const [email, setEmail] = useState('');

	useEffect(() => {
		if (success) {
			setEmail('');
		}
	}, [success]);

	return (
		<form
			{...props}
			onSubmit={e => {
				e.preventDefault();

				if (loading) {
					return;
				}

				resetPassword(email);
			}}
			className="reset-password-form"
		>
			<VerticleList spacing={2}>
				<TextField
					type="email"
					name="email"
					placeholder="Email address"
					value={email}
					onChange={e => setEmail(e.target.value)}
					required
					autoFocus
					fullWidth
				/>

				<Button
					fullWidth
					color="primary"
					variant="contained"
					type="submit"
				>
					{loading ? (
						<CircularProgress size={20} />
					) : (
						'Request Password Reset'
					)}
				</Button>
			</VerticleList>
		</form>
	);
};
