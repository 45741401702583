import { Alert, AlertProps } from '@material-ui/lab';

export type ErrorProps = AlertProps & {
	message: string;
};

export const Error: React.FC<ErrorProps> = ({ message, ...props }) => (
	<Alert
		style={{
			marginLeft: 0,
			marginRight: 0
		}}
		variant="filled"
		severity="error"
		{...props}
	>
		{message}
	</Alert>
);
