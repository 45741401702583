import { Alert } from '@material-ui/lab';
import { CircularProgress, Typography } from '@material-ui/core';

import { Error, VerticleList } from '../../../components';
import { Card, ChangePasswordForm } from '../../components';
import { useChangePasswordMutation } from '../../graphql';
import { useUser } from '../../hooks';

export const Account: React.FC = () => {
	const user = useUser();

	const [
		changePassword,
		{ data, loading, error }
	] = useChangePasswordMutation();

	if (!user) {
		return <CircularProgress />;
	}

	return (
		<VerticleList spacing={2}>
			<Card {...user} editable />

			<Typography variant="h4">Change Password</Typography>

			<ChangePasswordForm
				loading={loading}
				success={data?.changePassword}
				changePassword={(password, newPassword) =>
					changePassword({
						variables: {
							userId: user.id,
							currentPassword: {
								email: user.email,
								password
							},
							newPassword
						}
					})
				}
			/>

			{error && <Error message={error.message} />}

			{data && !data.changePassword && (
				<Error message="Failed to change your password. Please contact support." />
			)}

			{data && data.changePassword && (
				<Alert severity="success">Password changed successfully!</Alert>
			)}
		</VerticleList>
	);
};
