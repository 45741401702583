import { useEffect } from 'react';
import { useRecoilValue, useRecoilState, useResetRecoilState } from 'recoil';
import {
	Button,
	CircularProgress,
	createStyles,
	makeStyles
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { EditableText, VerticleList, Error } from '../../../components';
import {
	editFirstNameState,
	editLastNameState,
	nameTouchedState,
	nameValidationState
} from '../../atoms';
import { useUser } from '../../hooks';
import { useEditProfileMutation } from '../../graphql';

const useStyles = makeStyles(theme =>
	createStyles({
		inputs: {
			display: 'flex'
		},
		spinner: {
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center'
		}
	})
);

export const EditName: React.FC = () => {
	const classes = useStyles();
	const user = useUser();
	const nameTouched = useRecoilValue(nameTouchedState);
	const nameValidation = useRecoilValue(nameValidationState);
	const resetTouchedFirstName = useResetRecoilState(
		editFirstNameState.touchedState
	);
	const resetTouchedLastName = useResetRecoilState(
		editLastNameState.touchedState
	);

	const [firstName, setFirstName] = useRecoilState(editFirstNameState.state);
	const [lastName, setLastName] = useRecoilState(editLastNameState.state);

	const [editProfile, { data, loading, error }] = useEditProfileMutation();

	useEffect(() => {
		if (data) {
			resetTouchedFirstName();
			resetTouchedLastName();
		}
	}, [data, resetTouchedFirstName, resetTouchedLastName]);

	useEffect(() => {
		if (user) {
			setFirstName(user.profile.firstName);
			setLastName(user.profile.lastName);
		}
	}, [setFirstName, setLastName, user]);

	if (!user) {
		return (
			<div className={classes.spinner}>
				<CircularProgress size={30} />
			</div>
		);
	}

	return (
		<VerticleList spacing={2}>
			<div className={classes.inputs}>
				<EditableText
					label="First name"
					{...editFirstNameState}
					editOnFocus
				/>

				<EditableText
					label="Last name"
					{...editLastNameState}
					editOnFocus
				/>
			</div>

			{nameTouched && (
				<Button
					color="primary"
					variant="contained"
					disabled={nameValidation !== undefined}
					onClick={() => {
						if (loading) return;
						if (!nameTouched) return;
						if (nameValidation !== undefined) return;

						editProfile({
							variables: {
								profile: {
									id: user.profile.id,
									firstName,
									lastName
								}
							}
						});
					}}
				>
					{loading ? <CircularProgress size={20} /> : 'Save'}
				</Button>
			)}

			{data && (
				<Alert severity="success">Profile updated successfully.</Alert>
			)}

			{error && <Error message={error.message} />}
		</VerticleList>
	);
};
