import { useEffect, useState } from 'react';
import {
	Plugins,
	PushNotification,
	PushNotificationToken,
	PushNotificationActionPerformed,
	Capacitor
} from '@capacitor/core';

const { PushNotifications } = Plugins;
const { isNative } = Capacitor;

export const usePushNotifications = (): Error[] => {
	const [errors, setErrors] = useState<Error[]>([]);

	useEffect(() => {
		if (isNative) {
			// Request permission to use push notifications
			// iOS will prompt user and return if they granted permission or not
			// Android will just grant without prompting
			PushNotifications.requestPermission()
				.then(result => {
					if (result.granted) {
						// Register with Apple / Google to receive push via APNS/FCM
						PushNotifications.register();
					} else {
						// Show some error
						console.log('error');
						console.log(JSON.stringify(result));
					}
				})
				.catch(error => setErrors(errors => [...errors, error]));

			PushNotifications.addListener(
				'registration',
				(token: PushNotificationToken) => {
					console.log(
						'Push registration success, token: ' + token.value
					);
				}
			);

			PushNotifications.addListener('registrationError', error => {
				console.log('Error on registration: ' + JSON.stringify(error));
				setErrors(errors => [...errors, error]);
			});

			PushNotifications.addListener(
				'pushNotificationReceived',
				(notification: PushNotification) => {
					console.log(
						'Push received: ' + JSON.stringify(notification)
					);
				}
			);

			PushNotifications.addListener(
				'pushNotificationActionPerformed',
				(notification: PushNotificationActionPerformed) => {
					console.log(
						'Push action performed: ' + JSON.stringify(notification)
					);
				}
			);
		}
	}, []);

	return errors;
};
