import { ValidationError } from '../../../types';
import { RegistrationFormState } from '../../types';

import { validateEmail, validateName, validatePassword } from '../../../utils';

export const validateRegistrationForm = (
	state: RegistrationFormState
): ValidationError[] => {
	const errors: ValidationError[] = [];

	if (!validateEmail(state.email)) {
		errors.push({
			field: 'email',
			message: 'Invalid email address.'
		});
	}

	const passwordErrors = validatePassword(state.password);

	for (const error of passwordErrors) {
		errors.push(error);
	}

	if (state.password !== state.confirmPassword) {
		errors.push({
			field: 'confirmPassword',
			message: 'Passwords do not match.'
		});
	}

	if (!validateName(state.firstName)) {
		errors.push({
			field: 'firstName',
			message: 'Invalid first name.'
		});
	}

	if (!validateName(state.lastName)) {
		errors.push({
			field: 'lastName',
			message: 'Invalid last name.'
		});
	}

	return errors;
};
