import { useState, useEffect } from 'react';
import { TextField, Button, CircularProgress } from '@material-ui/core';

import { VerticleList } from '../../../components';
import { validateChangePasswordForm } from '../../utils';

export type ChangePasswordFormProps = React.HTMLAttributes<HTMLFormElement> & {
	changePassword(password: string, newPassword: string): void;
	loading: boolean;
	success: boolean | undefined;
};

export const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({
	changePassword,
	loading,
	success,
	...props
}) => {
	const [password, setPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [confirmNewPassword, setConfirmNewPassword] = useState('');

	const [newPasswordErrors, setNewPasswordErrors] = useState<string[]>([]);
	const [confirmNewPasswordErrors, setConfirmNewPasswordErrors] = useState<
		string[]
	>([]);

	useEffect(() => {
		if (success) {
			setPassword('');
			setNewPassword('');
			setConfirmNewPassword('');
		}
	}, [success]);

	return (
		<form
			{...props}
			onSubmit={e => {
				e.preventDefault();

				if (loading) {
					return;
				}

				const validationErrors = validateChangePasswordForm({
					newPassword,
					confirmNewPassword
				});

				setNewPasswordErrors(
					validationErrors
						.filter(error => error.field === 'newPassword')
						.map(({ message }) => message)
				);

				setConfirmNewPasswordErrors(
					validationErrors
						.filter(error => error.field === 'confirmNewPassword')
						.map(({ message }) => message)
				);

				if (validationErrors.length === 0) {
					changePassword(password, newPassword);
				}
			}}
			className="change-password-form"
		>
			<VerticleList spacing={2}>
				<TextField
					type="password"
					name="password"
					placeholder="Current password"
					value={password}
					onChange={e => setPassword(e.target.value)}
					required
					autoFocus
					fullWidth
				/>

				<TextField
					type="password"
					name="password"
					placeholder="New password"
					value={newPassword}
					onChange={e => setNewPassword(e.target.value)}
					required
					fullWidth
					error={newPasswordErrors.length > 0}
					helperText={newPasswordErrors.join(' ')}
				/>

				<TextField
					type="password"
					name="password"
					placeholder="Confirm new password"
					value={confirmNewPassword}
					onChange={e => setConfirmNewPassword(e.target.value)}
					required
					fullWidth
					error={confirmNewPasswordErrors.length > 0}
					helperText={confirmNewPasswordErrors.join(' ')}
				/>

				<Button
					fullWidth
					color="primary"
					variant="contained"
					type="submit"
				>
					{loading ? (
						<CircularProgress color="inherit" size={20} />
					) : (
						'Change Password'
					)}
				</Button>
			</VerticleList>
		</form>
	);
};
