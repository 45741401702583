import {
	createStyles,
	makeStyles,
	Theme,
	Card as MaterialCard,
	CardProps as MaterialCardProps
} from '@material-ui/core';
import { Person } from '@material-ui/icons';

import { UserFieldsFragment } from '../../graphql';
import { EditName } from '..';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			padding: theme.spacing(2)
		},
		right: {
			flexGrow: 1,
			paddingLeft: theme.spacing(2)
		}
	})
);

export type CardProps = MaterialCardProps &
	UserFieldsFragment & {
		editable?: boolean;
	};

export const Card: React.FC<CardProps> = ({
	email,
	profile,
	admin,
	verified,
	id,
	editable = false,
	...props
}) => {
	const styles = useStyles();

	return (
		<MaterialCard {...props} className={styles.root}>
			<Person
				color="primary"
				style={{ width: 40, maxWidth: 40, height: 40, flexGrow: 1 }}
			/>

			<div className={styles.right}>
				<div>{email}</div>

				{editable ? (
					<EditName />
				) : (
					<div>
						{profile.firstName} {profile.lastName}
					</div>
				)}
			</div>
		</MaterialCard>
	);
};
