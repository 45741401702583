import { Route, Routes } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { Profile, Account } from './views';
import { authenticatedState } from '../Auth/atoms';
import { useUser } from './hooks';
import { VerificationNotification } from './components';
import { VerticleList } from '../components';

export const User: React.FC = () => {
	const authenticated = useRecoilValue(authenticatedState);
	const user = useUser();

	if (!authenticated) {
		return <></>;
	}

	return (
		<VerticleList spacing={2}>
			{user && !user.verified && (
				<VerificationNotification userId={user.id} />
			)}

			<Routes>
				<Route path="/account" element={<Account />} />
				<Route path="/profile" element={<Profile />} />
				<Route path="/" element={<Profile />} />
			</Routes>
		</VerticleList>
	);
};
