import { Suspense, useRef } from 'react';
import { useNavigate } from 'react-router';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Menu, Person } from '@material-ui/icons';
import {
	Box,
	Container,
	AppBar,
	Toolbar,
	IconButton,
	Typography,
	Button,
	createStyles,
	makeStyles
} from '@material-ui/core';

import { drawerOpenState } from '../../atoms';
import { authenticatedState } from '../../Auth/atoms';
import { Menu as UserMenu } from '../../User/components';
import { userMenuOpenState } from '../../User/atoms';

const useStyles = makeStyles(theme =>
	createStyles({
		root: {
			flexGrow: 1
		},
		menuButton: {
			marginRight: theme.spacing(2)
		},
		title: {
			flexGrow: 1
		},
		padding: {
			paddingTop: 'env(safe-area-inset-top)',
			paddingLeft: `max(env(safe-area-inset-left), ${theme.spacing(
				2
			)}px)`,
			paddingRight: `max(env(safe-area-inset-right), ${theme.spacing(
				2
			)}px)`
		}
	})
);

export const TopBar: React.FC = () => {
	const navigate = useNavigate();
	const classes = useStyles();
	const setDrawerOpen = useSetRecoilState(drawerOpenState);
	const authenticated = useRecoilValue(authenticatedState);
	const [userMenuOpen, setUserMenuOpen] = useRecoilState(userMenuOpenState);
	const ref = useRef<HTMLButtonElement>(null);

	return (
		<AppBar position="static">
			<Container fixed disableGutters maxWidth="xs">
				<Box className={classes.padding}>
					<Toolbar disableGutters>
						<IconButton
							edge="start"
							className={classes.menuButton}
							color="inherit"
							aria-label="menu"
							onClick={() => setDrawerOpen(true)}
						>
							<Menu />
						</IconButton>

						<Typography
							onClick={() => navigate('/')}
							variant="h6"
							className={`${classes.title} cursor-pointer`}
						>
							Boiler Plate
						</Typography>

						{authenticated ? (
							<>
								<IconButton
									ref={ref}
									edge="start"
									className={classes.menuButton}
									color="inherit"
									aria-label="user menu"
									onClick={() =>
										setUserMenuOpen(!userMenuOpen)
									}
								>
									<Person />
								</IconButton>

								<Suspense fallback="loading">
									<UserMenu
										anchorEl={ref.current}
										open={userMenuOpen}
										onClose={() => setUserMenuOpen(false)}
									/>
								</Suspense>
							</>
						) : (
							<Button
								onClick={() => navigate('/auth')}
								color="inherit"
							>
								Login
							</Button>
						)}
					</Toolbar>
				</Box>
			</Container>
		</AppBar>
	);
};
