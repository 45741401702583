import {
	createStyles,
	makeStyles,
	Theme,
	useTheme
} from '@material-ui/core/styles';
import {
	IconButton,
	SwipeableDrawer,
	List,
	Divider,
	ListItem,
	ListItemIcon,
	ListItemText
} from '@material-ui/core';
import { ChevronLeft, ChevronRight, Inbox, Mail } from '@material-ui/icons';
import { useRecoilState } from 'recoil';

import { drawerOpenState } from '../../atoms';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex'
		},
		appBar: {
			transition: theme.transitions.create(['margin', 'width'], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen
			})
		},
		appBarShift: {
			width: `calc(100% - ${drawerWidth}px)`,
			marginLeft: drawerWidth,
			transition: theme.transitions.create(['margin', 'width'], {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen
			})
		},
		menuButton: {
			marginRight: theme.spacing(2)
		},
		hide: {
			display: 'none'
		},
		list: {
			width: drawerWidth,
			paddingTop: 'env(safe-area-inset-top)',
			paddingBottom: 'env(safe-area-inset-bottom)',
			paddingLeft: 'env(safe-area-inset-left)'
		},
		drawerHeader: {
			display: 'flex',
			alignItems: 'center',
			padding: theme.spacing(0, 1),
			// necessary for content to be below app bar
			...theme.mixins.toolbar,
			justifyContent: 'flex-end'
		},
		content: {
			flexGrow: 1,
			padding: theme.spacing(3),
			transition: theme.transitions.create('margin', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen
			}),
			marginLeft: -drawerWidth
		},
		contentShift: {
			transition: theme.transitions.create('margin', {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen
			}),
			marginLeft: 0
		}
	})
);

export const Nav: React.FC = () => {
	const [drawerOpen, setDrawerOpen] = useRecoilState(drawerOpenState);
	const classes = useStyles();
	const theme = useTheme();

	const handleOpen = () => setDrawerOpen(true);
	const handleClose = () => setDrawerOpen(false);

	return (
		<SwipeableDrawer
			onOpen={handleOpen}
			onClose={handleClose}
			anchor="left"
			open={drawerOpen}
			// swipeAreaWidth={40}
			disableSwipeToOpen={false}
		>
			<div className={classes.list}>
				<div className={classes.drawerHeader}>
					<IconButton onClick={handleClose}>
						{theme.direction === 'ltr' ? (
							<ChevronLeft />
						) : (
							<ChevronRight />
						)}
					</IconButton>
				</div>

				<Divider />

				<List>
					{['Inbox', 'Starred', 'Send email', 'Drafts'].map(
						(text, index) => (
							<ListItem button key={text}>
								<ListItemIcon>
									{index % 2 === 0 ? <Inbox /> : <Mail />}
								</ListItemIcon>

								<ListItemText primary={text} />
							</ListItem>
						)
					)}
				</List>

				<Divider />

				<List>
					{['All mail', 'Trash', 'Spam'].map((text, index) => (
						<ListItem button key={text}>
							<ListItemIcon>
								{index % 2 === 0 ? <Inbox /> : <Mail />}
							</ListItemIcon>

							<ListItemText primary={text} />
						</ListItem>
					))}
				</List>
			</div>
		</SwipeableDrawer>
	);
};
