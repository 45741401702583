import { atom, selector } from 'recoil';
import { persist } from '../utils';
import { getUserIdFromJWT } from './utils';

export const jwtState = atom<string | null | undefined>({
	key: 'jwt',
	default: undefined,
	effects_UNSTABLE: [persist]
});

export const userIdState = selector<string | undefined>({
	key: 'userId',
	get({ get }) {
		const jwt = get(jwtState);

		return getUserIdFromJWT(jwt);
	}
});

export const authenticatedState = selector<boolean>({
	key: 'authenticated',
	get({ get }) {
		const userId = get(userIdState);

		return userId !== undefined;
	}
});
