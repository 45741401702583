import { Alert } from '@material-ui/lab';
import { CircularProgress } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { namedOperations } from '../../User/graphql';
import { useVerifyEmailMutation } from '../../graphql';
import { Error } from '../../components';
import { useRefreshSession } from '../../hooks';
import { defined } from '../../utils';
import { jwtState } from '../../Auth/atoms';

export const Verify: React.FC = () => {
	const { token } = useParams();

	const jwt = useRecoilValue(jwtState);
	const [loaded, setLoaded] = useState(false);
	const refresh = useRefreshSession(() => setLoaded(true));
	const [verifyEmail, { data, error }] = useVerifyEmailMutation({
		awaitRefetchQueries: true,
		refetchQueries: defined(jwt) ? [namedOperations.Query.User] : undefined,
		variables: { token },
		onCompleted() {
			console.log('onCompleted');
			console.log('onCompleted: jwt', jwt);

			if (defined(jwt)) {
				refresh();
			} else {
				setLoaded(true);
			}
		}
	});

	useEffect(() => {
		verifyEmail();
	}, [verifyEmail]);

	if (error) {
		return <Error message={error.message} />;
	}

	if (loaded && !data) {
		return <Error message="Something went wrong. Please try again." />;
	}

	if (!loaded || !data) {
		return <CircularProgress />;
	}

	if (!data.verifyEmail) {
		return (
			<Error message="The link you clicked has expired. Please request a new verification email." />
		);
	}

	return (
		<div className="verify">
			<Alert variant="filled" severity="success">
				Your email has been verified!
			</Alert>
		</div>
	);
};
