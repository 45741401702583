import { Alert } from '@material-ui/lab';
import { Typography } from '@material-ui/core';

import { VerticleList, Error } from '../../../components';
import { RequestPasswordResetForm } from '../../components';
import { useRequestPasswordResetMutation } from '../../graphql';

export const ForgotPassword: React.FC = () => {
	const [
		requestPasswordReset,
		{ data, loading, error }
	] = useRequestPasswordResetMutation();

	return (
		<VerticleList spacing={2}>
			<Typography variant="h4">Forgot Your Password?</Typography>

			<Typography variant="body1">
				Enter you email address. If an account exists, you'll receive an
				email with instructions to reset your password.
			</Typography>

			<RequestPasswordResetForm
				loading={loading}
				success={data?.requestPasswordReset}
				resetPassword={email =>
					requestPasswordReset({ variables: { email } })
				}
			/>
			{error && <Error message={error.message} />}

			{data && !data.requestPasswordReset && (
				<Error message="Failed to request a password reset. Please verify that the email address you entered is correct." />
			)}

			{data && data.requestPasswordReset && (
				<Alert severity="success">
					Email sent! Please check your inbox.
				</Alert>
			)}
		</VerticleList>
	);
};
