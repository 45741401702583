import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { Layout } from '..';
import { Home, PasswordReset, Verify } from '../../views';
import { useRefreshSession } from '../../hooks';
import { Auth } from '../../Auth';
import { User } from '../../User';
import { authenticatedState } from '../../Auth/atoms';

export const Router: FC = () => {
	useRefreshSession();
	const authenticated = useRecoilValue(authenticatedState);

	return (
		<Layout>
			<Routes>
				{authenticated && (
					<>
						<Route path="user/*" element={<User />} />
					</>
				)}

				<Route path="auth/*" element={<Auth />} />

				<Route path="/verify/:token">
					<Verify />
				</Route>

				<Route path="/pwreset/:token">
					<PasswordReset />
				</Route>

				<Route path="/" element={<Home />} />
			</Routes>
		</Layout>
	);
};
