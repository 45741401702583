import { Button, Divider } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { VerticleList } from '../../../components';
import { LoginForm, OAuthSigninButtons } from '../../components';
import { useLoginMutation } from '../../graphql';
import { jwtState } from '../../atoms';

export const Login: React.FC = () => {
	const navigate = useNavigate();
	const [error, setError] = useState<string>();
	const [login, { data, error: apolloError }] = useLoginMutation();
	const setJwt = useSetRecoilState(jwtState);

	useEffect(() => {
		if (apolloError) {
			setError(apolloError.message);
		}
	}, [apolloError]);

	useEffect(() => {
		if (data && data.login) {
			setJwt(data.login);

			navigate('/user');
		}
	}, [data, navigate, setJwt]);

	return (
		<VerticleList spacing={2}>
			<LoginForm
				login={(email, password) => {
					login({
						variables: {
							credentials: {
								email,
								password
							}
						}
					});
				}}
			/>

			{error && (
				<Alert
					style={{
						marginLeft: 0,
						marginRight: 0
					}}
					variant="filled"
					severity="error"
				>
					{error}
				</Alert>
			)}

			<Button
				style={{ color: '#fff' }}
				variant="contained"
				color="secondary"
				fullWidth
				onClick={() => navigate('register')}
			>
				Sign up
			</Button>

			<Divider component="div" />

			<OAuthSigninButtons />
		</VerticleList>
	);
};
