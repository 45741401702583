import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { Login, Register, ForgotPassword } from './views';
import { authenticatedState } from './atoms';

export const Auth: FC = () => {
	const authenticated = useRecoilValue(authenticatedState);

	return (
		<Routes>
			{!authenticated && (
				<>
					<Route path="/" element={<Login />} />

					<Route path="/register" element={<Register />} />
					<Route path="/forgot" element={<ForgotPassword />} />
				</>
			)}
		</Routes>
	);
};
